.ProductDetailView{
	width:100%;
	position: absolute;
	bottom:calc(var(--footer-height) + var(--stage_navigation-height) + var(--bottom-height) + var(--border-width)*2);
	top:0;
	color:var(--black);
	display:flex;
	align-items: stretch;
	flex-direction: column;
}

.ProductDetailView.nested{bottom:0;}

.ProductDetailView .content{flex:1 1 auto;}

.ProductDetailView .stageNavigation{ flex: none;}
.ProductDetailView>.bottom{ flex:none;}


.ProductDetailView .content{display:flex;	align-items: stretch;flex-direction: row;}
.ProductDetailView .content{margin-bottom:0px;overflow: hidden;}
.ProductDetailView .content>.left{flex:1 1 auto;border-right:var(--border-width) solid white;}
.ProductDetailView .content>.right{flex:none;width: calc(100vw * var(--right-width) + var(--border-width));right:0;background-color:var(--bronze-light);color:var(--red);}

.ProductDetailView .stage{width:100%;height:100%;}

.ProductDetailView .stageNavigation{position:relative;height:calc(var(--stage_navigation-height) - var(--border-width)*2);border-top:var(--border-width) solid white;width:100%;bottom:0;background-color: var(--bronze-dark);}
.ProductDetailView .stageNavigation .title{position: absolute;right:50px;top:0;line-height: var(--stage_navigation-height);font-size: 20px;}


.ProductDetailView .stageNavigation>.nav{height:100%;background-color: var(--bronze-middle-dark);}
.ProductDetailView .stageNavigation>.nav>*{display:inline-block;width:80px;height:100%;border-left:0.2px solid var(--white-semi)}
.ProductDetailView .stageNavigation>.nav>*:hover{background-color: var(--white-semi_2);}
.ProductDetailView .stageNavigation>.nav>*:hover>div{background-color: var(--bronze-dark);}
.ProductDetailView .stageNavigation>.nav>*:first-child{border-width: 0;}

.ProductDetailView .stageNavigation>.nav>*>div{mask-position: center;mask-repeat: no-repeat;mask-size:calc(100% - 20px);height: 100%;width: 100%;background-color: var(--white);}
.ProductDetailView .stageNavigation>.nav>*.active>div{background-color: var(--red);transition: background-color 0.5s;}

.ProductDetailView .stageNavigation>.nav1{margin-left:var(--margin);margin-right:var(--margin);}
.ProductDetailView.nested .stageNavigation>.nav1{width:50%;}
.ProductDetailView .stageNavigation ._images>div {
  mask-image: url('/public/images/icons/imageBtn.svg');
}

.ProductDetailView .stageNavigation ._videos>div {
  mask-image: url('/public/images/icons/playBtn.svg');
}

.ProductDetailView .stageNavigation ._3d>div {
  mask-image: url('/public/images/icons/3DBtn.svg');
}

.ProductDetailView .stageNavigation ._info>div {
  mask-image: url('/public/images/icons/infoBtn.svg');
}

.ProductDetailView .stageNavigation ._prices>div {
  mask-image: url('/public/images/icons/costBtn.svg');
}

.ProductDetailView .stageNavigation .active{background-color: white;transition: background-color 0.5s}

.ProductDetailView .stageNavigation>.nav2{position:absolute;right:var(--margin);top:0;bottom:0;width:auto;background-color: var(--bronze-middle-dark);}
.ProductDetailView .stageNavigation .print>div {
  mask-image: url('/public/images/icons/printBtn.svg');
}

.ProductDetailView .stageNavigation .fullscreen>div {
  mask-image: url('/public/images/icons/fullScreenBtn.svg');
}





.ProductDetailView>.bottom{flex:none;width:100%;height:var(--bottom-height);color:var(--white);background-color: var(--red);border-top: var(--border-width) solid var(--white);}
.ProductDetailView>.bottom span{display:inline-block; margin:10px 20px;}



.ingredients{position: relative;font-size:15px;line-height: 1.4; width:100%}
.ingredients>p{width:100%;display: flex;align-items: stretch;}
.ingredients>p>.lab {
  flex: 1 1 auto;
  background-image: url('/public/images/icons/listPoints_red.png');
  background-size: calc(1* 14px);
  background-position: 8px calc(1 * 4px);
  background-repeat: no-repeat;
  padding: 0 0 0 calc(1 * 25px);
}
.ingredients>p>.lab{float: left; width:calc(100% - 90px);margin-right:0px; overflow: hidden; white-space: nowrap }
.ingredients>p>.lab:after{font-weight:lighter; content: " .........................................................................................................................................................................................................................................."}
.ingredients>p>.val{flex:none; width: 50px; overflow: hidden;text-align: right;font-weight: lighter;}


.ProductDetailView .content .right{display: flex;flex-direction: column;}
.ProductDetailView .content>.right>*{margin:var(--margin)}
.ProductDetailView .content>.right .head{display:flex;flex:none;margin-bottom:0}
.ProductDetailView .content>.right .head>._1{flex:1 1 auto}
.ProductDetailView .content>.right .head>._2{flex:none;display:none;}
.ProductDetailView .content>.right h1{font-weight: bold;font-size:var(--fsize_9);line-height:1.1}
.ProductDetailView .content>.right h2{font-weight: normal;font-size:var(--fsize_7);line-height:1.2}
.ProductDetailView .content>.right h3{font-weight: normal;font-size:var(--fsize_6);color:var(--bronze-dark);line-height:1.2}
.ProductDetailView .content>.right .head img{height:100px}

.ProductDetailView .content>.right .hr{background-color:var(--bronze-dark);margin-top:0;margin-bottom:0;}



.ProductDetailView .content>.right .body{flex:1 1 auto;}
.right .ingredients{font-size:var(--fsize_7);}
.right .ingredients>p>.lab{background-image:none;}
.right .ingredients>p>.val{font-weight:normal;}



.ProductDetailView .content>.right .foot{flex:none;margin-bottom:0;}
.ProductDetailView .content>.right .foot>img{position:absolute;width:100%;bottom:30px;}
.ProductDetailView .content>.right .foot>.bg {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 300px;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: url('/public/images/lambertz-sonne-footer.png');
}

.ProductDetailView .content>.right .certs{}
.ProductDetailView .content>.right .certs .hr{margin:5px 0 10px 0}


.ProductDetailView .badges{
  margin-top:20px;
}
.ProductDetailView .content>.right .icons, .ProductDetailView .badges{
  display: flex;
  gap:var(--margin);
}


.ProductDetailView .content>.right .icons>*, .ProductDetailView .badges>*{height:60px;}

.ProductDetailView .organic_certification{
  display: inline-block;
  font-size: 60%;

  white-space: pre-line;

  display: flex;
  align-items: center;

}
.portrait .ProductDetailView .organic_certification{
  margin-left:1em;
}


.ProductDetailView .content>.right .badge{
	width:100%;
}

.ProductDetailView .content>.right .bgimg{
	width:calc(100% + var(--margin) * 2);
	height:100px;
	margin:-60px calc(var(--margin) * -1) 0 calc(var(--margin) * -1);
	background-size:cover;
	background-position:center;
	background-image: url(/public/images/lambertz-sonne-footer.png);
}

.portrait  .ProductDetailView.nested{	height:calc(100% - var(--footer-height) - var(--border-width) * 3 )}

.portrait .ProductDetailView{
	bottom:0;
	/*display: flex;*/
	height:calc(100% - var(--footer-height) * 2);
	overflow:auto;
}

.portrait .ProductDetailView .left{border-left:var(--border-width) solid white;border-right:var(--border-width) solid white;}


.portrait .ProductDetailView ._phead{display:flex;align-content: center;}
.portrait .ProductDetailView ._phead>*{flex:1 1 auto}
.portrait .ProductDetailView ._phead>.badges{flex:0 0 auto;height:50px;margin:auto 0}
.portrait .ProductDetailView ._phead>.badges>*{height:50px;margin-left:5px;}

.portrait .ProductDetailView .stageNavigation{margin: 0 var(--border-width) 0 var(--border-width);width:auto}
.portrait .ProductDetailView .stageNavigation>.nav1{margin-left:0;margin-right:0;width:auto}
.portrait .ProductDetailView .stageNavigation>.nav1>*{max-width:calc(100% / 5 - 1px);}


.portrait .ProductDetailView .stageNavigation>.nav1{display:flex}
.portrait .ProductDetailView .stageNavigation>.nav1>*{max-width:unset;width:unset;flex:1 1 auto;max-width:80px;}



.portrait .ProductDetailView h1{color:var(--red);font-size:var(--fsize_9);font-weight:bold;}
.portrait .ProductDetailView h2, .ContentInfo h3{color:var(--red);font-size:var(--fsize_7);font-weight:normal;line-height: 1.4;white-space: pre-line}
.portrait .ProductDetailView h3{color:var(--bronze-dark);}
.portrait .ProductDetailView .hr{margin:var(--border-width) 0 var(--border-width) 0}

.portrait .fbottom{/*flex:1 1 auto;overflow: auto;*/}
.portrait .fbottom .ingredients{margin:var(--border-width);margin-top:calc(var(--border-width) * 2);width:calc(100% - var(--border-width) * 2)}
.portrait .fbottom .ingredients>p>.lab{background-size:var(--fsize_4);background-position:8px calc(var(--fsize_4) / 3);padding:0 0 0 25px;}
.portrait .fbottom .ingredients>p>.val{width:10vmin;}

.portrait .ProductDetailView .mobileimg{width:100%;height:auto;background-color: var(--bronze-light);}
.portrait .ProductDetailView .mobileimg img {width:100%;height:auto;}
