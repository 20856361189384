.LoginView {
  position: relative;
  background-color: var(--red);
  background-image: url(/public/images/bg.jpg);
  background-size: 100% 100%;
  height: 100%;
  color: var(--white)
}

.LoginView .window{
	position:absolute;left:50%;top:50%;transform: translate(-50%, -50%);
	border:1px solid var(--white-semi);height:600px;max-width:1500px;width:80vw;
}

.LoginView .window>.left, .LoginView .window>.right{width:50%;top:0;height:100%;}
.LoginView .window>.left{left:0;}
.LoginView .window>.right{position: absolute;right:0;}

.portrait .LoginView .window{
	border-width: 0;
	left:0;
	top:0;
	transform: none;
	max-width:auto;
	width:100%;
	margin-top:calc(var(--header-height) + 10vmin)
}

.portrait .LoginView .window>.left, .portrait .LoginView .window>.right{width:100%;}