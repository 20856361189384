.ContentPrices{
	position: relative;
	background-color:white;
	width:calc(100% - 40px);
	height:calc(100% - 40px);
	padding: 20px;
	--scale:1;
}

.ContentPrices h1{color:var(--red)}

.ContentPrices table{margin-top:20px;width:100%;}

.ContentPrices table tr>td{padding:10px;text-align:center;border:1px solid var(--bronze-light);width:auto;text-align:left; }
.ContentPrices table tr>td:first-child{background-color: var(--bronze-middle);color:var(--white);width:200px;font-size: var(--fsize_3);}

.ContentPrices table tr{cursor: pointer;}
.ContentPrices table tr:hover{background-color: var(--bronze-light);transition: background-color 0.5s;}
.ContentPrices table tr:hover>td:first-child{color: var(--bronze-dark);transition: color 0.5s;}

.portrait .ContentPrices table tr>td:first-child{width:100px;}