.ProductView{
	display: flex;
	height:calc(100vh - var(--header-height) - var(--footer-height));
}

.ProductView>.right
{
	flex-shrink:0;
	flex-grow: 0;
	--width: calc(100vw * var(--right-width) + var(--border-width));
	width:var(--width);
	
	background: var(--red-dark) url(/public/images/bg.jpg) no-repeat right top;
	background-size: 100% 100%;
	height:calc(100% - var(	--border-width));
}


.ProductView>.right .logo{
	background-image:url(/public/images/lambertz-logo-transparent.png);
	background-position: center 35%;
	background-size:60%;
	width:100%;
	height:100%;
}

.ProductView>.left
{
	flex-shrink: 1;
	flex-grow: 1;
	margin: var(--margin);
	display: flex;
	flex-direction: column;
	
	align-content:stretch;
	height:calc(100% - var(--margin));
	
}
.ProductView>.left>*{
	flex:0 0 none;
	margin-bottom:var(--border-width);
}



/*#region Filter*/
.ProductView .filter{
	background-color: var(--bronze-light);
	padding:8px 20px
}

.ProductView .filter .label
{
	color: var(--bronze-dark);
}

.ProductView .filter>.ia
{
	margin-left:50px;
	color:var(--red);
}

.ProductView .filter>.ia.sort
{
	background-image: url(/public/images/icons/sortNormal.svg);
	background-repeat: no-repeat;
	background-position: right center;
	padding-right:20px;
}
.ProductView .filter>.ia.sort.asc
{
	background-image: url(/public/images/icons/sortDown.svg);
}
.ProductView .filter>.ia.sort.desc
{
	background-image: url(/public/images/icons/sortUp.svg);
}
/*"/images/icons/sortDown.svg"
"/images/icons/sortUp.svg"
"/images/icons/sortNormal.svg"
*/
/*#endregion Filter*/

/*#region Breadcrumb*/
.ProductView .breadcrumb{
	padding-left:10px;
	background-color: var(--red);
	color:var(--white);
	font-size: 0.8em;
	font-weight: lighter;
}

.ProductView .breadcrumb>div{display:inline;}
.ProductView .breadcrumb a{display:inline-block;	color:var(--white);padding:10px;text-decoration: underline;}
.ProductView .breadcrumb span.divider{}
.ProductView .breadcrumb>div:last-child span.divider{display:none}
/*#endregion Breadcrumb*/

/*#region Products*/


/*#endregion Products*/

.portrait .ProductView{height:calc(100% - var(--header-height) );}
.portrait .ProductView>.left{width:0;
	margin: var(--margin);
	margin-bottom:0;
	margin-top:0;
	padding:0;
	display: flex;
	flex-direction: column;
	align-content:stretch;
	height:100%;
	position: relative;
}
.portrait .ProductView>.right{width:0}

