.MessageAlert{
	position: absolute;
	top:0;
	width:100%;
	
	z-index: 99999;	
	pointer-events: none;	
}


.MessageAlert .msg{
	display: inline-block;
	position: absolute;left:50%;top:0;transform: translateX(-50%);
	
	background-color: var(--bronze-light);
	padding:var(--margin);
	color:var(--bronze-dark);
	border-bottom-left-radius:var(--margin);border-bottom-right-radius:var(--margin);
	animation: 0.5s ease-out 0s 1 showMessageAlert;
	font-size: var(--fsize_5);
	line-height:var(--fsize_8);
}

.MessageAlert .msg.APP_MESSAGE_TYPE_ERROR{background-color:var(--red);color:var(--white)}
.MessageAlert .msg.APP_MESSAGE_TYPE_WARNING{background-color:var(--bronze-middle);color:var(--white)}

@-webkit-keyframes showMessageAlert {
	from { transform: translate(-50%, -100%);opacity: 0; }
		to {  transform: translate(-50%, 0%);opacity: 1; }
} 
@keyframes showMessageAlert {
	from { transform: translate(-50%, -100%);opacity: 0; }
		to {  transform: translate(-50%, 0%);opacity: 1; }
}


.portrait .MessageAlert .msg{padding:10px;padding-top:25px}