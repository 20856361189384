.UserDetails
{
 
}
.UserDetails .icon {
  background-image: url(/public/images/icons/person.png);
  width: 100%;
  height: 80px;
  background-size: contain;
  background-position: 35% 50%;
}

.UserDetails .content{top:calc(var(--header-height) + 0px);bottom:80px;overflow-y: auto;overflow-x: hidden;}

.UserDetails .lmbrtz_btn{position: absolute;bottom:20px;width:calc(100% - 64px - 28px);left:32px;color:var(--white);}
.UserDetails .lmbrtz_btn .green{position: absolute;width:50%;background-color: var(--green);top:0;left:0;height: 100%;line-height:34px;}
.UserDetails .lmbrtz_btn .red{margin-left:50%;width:50%;background-color: var(--red);top:0;left:0;height: 100%;line-height:24px;}

.UserDetails .content>.icon{border:none}
.UserDetails .content>.TextInput{display:table;width:calc(100% - 64px)}