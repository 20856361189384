.Content3D{
	position: relative;
	background-color:var(--bronze-light);
	width:100%;
	height:100%;	
}


.Content3D .modelViewer{
	width: 100%;
	height: 100%;
	background-color:var(--bronze-light);
	z-index:20;
	position: relative;
}
.Content3D .modelViewer *{background-color:var(--bronze-light) !important;}


.portrait .Content3D{
	height:35vh;	
/*	background-color: unset;*/
}

.portrait .Content3D .modelViewer{
	width:100%;
	height:100%;
	
	transform: translate(0, 0);
	min-height: unset;
}

.ar_tmp_link{position: absolute;z-index: 999;background-color: red;top:2em;height:4em}
