.Icon{
	display:inline-block;
	width:.9em;
	height:.9em;
	border-radius: 50%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}
/*
.Icon.thumb {
  background-image: url(/public/images/icons/ico_thumb.png);
}

.Icon.edit {
  background-image: url(/public/images/icons/ico_edit.png);
}

.Icon.duplicate {
  background-image: url(/public/images/icons/ico_duplicate.png);
}

.Icon.new {
  background-image: url(/public/images/icons/ico_new.png);
}

.Icon.dot {
  background-image: url(/public/images/icons/dot.png);
}

.Icon.plus {
  background-image: url(/public/images/icons/plus.png);
}

.Icon.minus {
  background-image: url(/public/images/icons/minus.png);
}

.Icon.delete {
  background-image: url(/public/images/icons/ico_delete.png);
}

.Icon.alert {
  background-image: url(/public/images/icons/ico_alert.png);
}

.Icon.left {
  background-image: url(/public/images/icons/left.png);
}

.Icon.right {
  background-image: url(/public/images/icons/right.png);
}

.Icon.check {
  background-image: url(/public/images/icons/check.png);
  background-size: 80%;
}

.Icon.checkbox {
  background-image: url(/public/images/icons/ico_checkbox.png);
  background-size: 110%;
}

.Icon.status_1 {
  background-image: url(/public/images/icons/dot.png);
}

.Icon.status_2 {
  background-image: none;
  width: .4em;
  height: .4em;
}


.Icon.close {
  background-image: url(/public/images/icons/ico_close_circle.png);
  background-color: transparent;
}

*/
.Icon.activity{background-image:none;width:3px;height:3px}


.Icon{background-color:var(--gray-light);}
.Icon.check{background-color:var(--white);}
.Icon.ok{background-color:var(--green);}
.Icon.error{background-color:var(--red);}

.Icon.blue, .Icon.plus,  .Icon.left,  .Icon.right{background-color:var(--main-color);}

.Icon-content{color:var(--main-color);}
.Icon.green{background-color:var(--green);}
.Icon.black{background-color:var(--black);}
/*
.Icon.contacts {
  background-image: url(/public/images/icons/ico_contacts.png);
  border-radius: 0;
  background-color: transparent
}

.Icon.views {
  background-image: url(/public/images/icons/ico_views.png);
  border-radius: 0;
  background-color: transparent
}

.Icon.main.contacts {
  background-image: url(/public/images/icons/ico_contacts_blue.png);
}

.Icon.main.views {
  background-image: url(/public/images/icons/ico_views_blue.png);
}

*/