.Button{
	display:inline-block;
	background-color: var(--blue);
	
	border:none;
	font-weight: normal;
	font-size:1.0em;
	cursor: pointer;
	padding:0 8px;
	user-select: none;
	text-decoration: none;

	color:var(--white);
	border:1px solid var(--red);
	background-color: var(--red);
	border-radius: 5px;
}


.Button-content{display:inline-block;vertical-align:middle;margin:0.3rem 0}
.Button .icon{display:inline-block;margin-right:5px;font-size: 80%;}
.Button .Icon{display:inline-block;vertical-align: middle;margin-right:.5em;}
/*.Button:active{background-color:var(--btn-hover);transition: filter .1s}*/
.Button:active{opacity:.6;transition: opacity 0s}
.Button:hover{opacity:.8;;transition: opacity .1s}