.ContentImage{
	position: relative;
	background-color:var(--bronze-light);
	width:100%;
	height:100%;	
	overflow:hidden
}

.ContentImage>img{
	position: absolute;
  top: 50%; 
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%; 
  min-height: 100%; 
  width: auto; 
  height: 100%;
  
	overflow: hidden;
	z-index:1;
	opacity: 0;
	transition: opacity 1s;
}

.wide .ContentImage>img
{
	width: 100%; 
  height: auto;
}

.ContentImage>img.active
{
	z-index: 95;
	opacity: 1;
	
}


.ContentImage .dots{bottom:25px;}

.ContentImage .ar{position:absolute;width:50px;height:50px;z-index:100;top:calc(50% - 25px);cursor: pointer;background-color: var(--white);border-radius: 50%;border-width: 0px !important;}
.ContentImage .ar>div{pointer-events: none;}

.ContentImage .ar>div{width: 100%;height: 100%;mask-size:55%;background-color: var(--bronze-dark);}
.ContentImage .ar:hover{background-color: var(--bronze-light);transition:background-color 0.5s;}
.ContentImage .ar:hover>div{mask-size:60%;transition:mask-size 0.1s;}

.ContentImage .left{left:var(--margin);}
.ContentImage .right{right:var(--margin);}

.ContentImage .left>div {
  mask-image: url('/public/images/icons/presentationLeft.svg');
}

.ContentImage .right>div {
  mask-image: url('/public/images/icons/presentationRight.svg');
}



.portrait .ContentImage{
	height:auto;	
	background-color: unset;
}


.portrait .ContentImage img{
	width:100%;
	height:auto;
	position: absolute;
	top:0;
	left:0;
	transform: translate(0, 0);
	min-height: unset;
}
.portrait .ContentImage img:first-child{
	position: relative;
}