
.ContentInfo{
	position:relative;
	background-color:white;
	width:100%;
	height:100%;
	padding:0;

	--scale:1;
	display: flex;
	flex-direction: row;
	overflow: hidden;
}

.__depr_nested .ContentInfo{
	/*height:calc(100vh - var(--header-height) - var(--footer-height) - var(--stage_navigation-height) - var(--bottom-height) - var(--border-width)*4 - var(--margin)*2);*/
}

.ContentInfo h1{color:var(--red);font-size:var(--fsize_9);font-weight:bold;}
.ContentInfo h2, .ContentInfo h3{color:var(--red);font-size:var(--fsize_7);font-weight:normal;line-height: 1.4;white-space: pre-line}
.ContentInfo h3{color:var(--bronze-dark);}

.ContentInfo .l{padding:var(--margin);flex:1 1 auto;width:calc(100vw - 100vw * var(--right-width) + var(--border-width));overflow:auto}
.ContentInfo .r{
	flex:none;
	width:calc(100vw * var(--right-width) + var(--border-width));
	background-size: cover;
	background-color: var(--bronze-light);
	display:flex;
	align-items: center;
}
.ContentInfo .r>img{width:100%;}

.fullscreen .ContentInfo .r{	
	width:calc(100vw * (var(--right-width) * 1) + var(--border-width) + 80px);	

}
.fullscreen .ContentInfo .l{	
	width:calc(100vw * (1 - var(--right-width)) + var(--border-width) - 80px);
	
}

.ContentInfo .l>*{}
.ContentInfo .l>*:last-child{flex:1 1 auto;overflow: auto;}
.ContentInfo .head{width:100%;display:flex}
.ContentInfo .head>div{flex:1 1 auto}
.ContentInfo .head>div.qrArea{flex:none}

.ContentInfo .qrArea{position: relative;top:unset;right:unset;}


.ContentInfo .part{margin:calc(var(--margin)/2) 0}

.ContentInfo .hr{margin-top:10px;margin-bottom:10px;}

.ContentInfo .list{
	column-count: 1;
}

.ContentInfo .list>div{
	background-image: url('/public/images/icons/listPoints.png');
	background-size:calc((var(--scale)) * 14px);
	background-position:0 calc(var(--scale) * 5px);
	background-repeat: no-repeat;
	padding:0 0 0 calc(var(--scale) * 25px);
	display:block;
	width: calc(100% - calc(var(--scale) * 30px));
	height:auto;
	line-height: 1.6;
	margin-bottom:5px;
	color:rgba(34,30,31,1);
	font-weight: lighter;
	font-size: calc(15px * var(--scale));
}

.ContentInfo .split{height:150px;display:flex;}
.ContentInfo .split>*{flex:1 1 auto;overflow:hidden}
.ContentInfo .split>img{flex:none;height:100%;}

.ContentInfo .split .ingredients{width:unset}

.ContentInfo table.packages{color:rgba(34,30,31,1);font-weight: lighter;text-align: left;font-size: 80%;width:100%;}
.ContentInfo table.packages a{color:var(--red);text-decoration: none;font-weight: normal;}
.ContentInfo table.packages td, .ContentInfo table.packages th{padding:10px;white-space: pre-line;vertical-align: middle;border:1px solid var(--bronze-light);font-size:100%;}
.ContentInfo table.packages th{}
.ContentInfo table.packages thead{font-weight: normal;color:var(--white);background-color: var(--bronze-middle);}
.ContentInfo table.packages tbody td{white-space: pre-line;vertical-align: middle;border:1px solid var(--bronze-light)}

.ContentInfo table.packages tbody tr{background-color: var(--bronze-light);transition: background-color .2s;}
.ContentInfo table.packages tbody tr:nth-child(odd){background-color:transparent}
.ContentInfo table.packages tbody tr:hover{background-color: var(--bronze-middle);transition: background-color .5s;}


.ContentInfo .flex_col{display:flex;align-content: stretch;}
.ContentInfo .flex_col .badges{text-align: right;flex:0 0 auto}
.ContentInfo .flex_col .list{flex: 1 1 auto;
}
.ContentInfo .flex_col .badges>*{height:calc(10px + 4vmin);margin-left:1vmin}
.fullscreen .ContentInfo{padding-left:50px;	width:calc(100% - 70px);}
.fullscreen .ContentInfo h1{margin-top:100px;}
.fullscreen .ContentInfo
{
	--scale:1.5;
}

.fullscreen .ContentInfo table.packages{margin-top:40px;}
.fullscreen .ContentInfo table.packages td, .ContentInfo table.packages th{font-size: calc(100% * (var(--scale)));}
.fullscreen  .ContentInfo table.packages th{font-size: calc(80% * (var(--scale) / 1.4) );}

.portrait  .ContentInfo .logo{width:100%;}

.portrait .fbottom .ContentInfo .ingredients{margin:calc(var(--border-width) * 2) 0 0 0;width:100%;}
.portrait .fbottom .ContentInfo .ingredients>p>.lab, .portrait .ContentInfo .list>div{background-size:var(--fsize_4);background-position:0px calc(var(--fsize_4) / 2.2);padding:0 0 0 20px;}
.portrait .fbottom .ContentInfo .ingredients>p>.val{font-weight: normal;}


.ContentInfo .packages_list_mobile{overflow: unset !important;margin-top:20px;margin-left:-2px}
.ContentInfo .package_mobile{position: relative; overflow: unset !important;}
.ContentInfo .package_mobile .dropdown_ar{
	position: absolute;
	top:5px;
	right:var(--margin);
	width:31px; 
	height:31px;
	background-image: url(/public/images/icons/dropdown_ar.png);
	transform: rotateZ(180deg);
}

.ContentInfo .package_mobile.opened .dropdown_ar{
	transform: rotateZ(0);
}

.ContentInfo .package_row{display:table-row;height:auto;table-layout: fixed;}

.ContentInfo .package_row>div{--padding:10px;--padding-v:15px;
	display:table-cell;
	padding:var(--padding-v) var(--padding);
}
.ContentInfo .package_label{
	background-color:var(--bronze-middle);
	color:var(--white);
	width:25vw;
	
	font-size:90%;
	border-bottom:1px solid var(--white);
	border-right:1px solid var(--white);

	word-wrap: break-word;
  overflow-wrap: break-word;
	hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  word-wrap: break-word; 
}

.ContentInfo .package_row.package_title .package_value{padding-right:50px;width:calc(75vw - var(--margin) * 8 - 50px);}

.ContentInfo .package_value{font-weight: lighter;border-bottom:1px solid var(--bronze-light);width:calc(75vw - var(--margin) * 11);}
.ContentInfo .package_row.package_title .package_value{background-color: var(--red);color:var(--white)}

.ContentInfo .package_mobile .collapsable{max-height:0px;;overflow: hidden; transition: max-height .5s ease-out;}
.ContentInfo .package_mobile.opened .collapsable{max-height: 800px;;overflow: hidden; transition: max-height 0.5s ease-out;}