.BackButton{
	display:inline-block;
	color:var(--white);
	background-size: 46px;
	height:46px;
	padding: 30px 75px 0 0; 
	background-position:right center;
	background-image: url('/public/images/icons/back.png');
}

.portrait .BackButton{
	padding-left:0;
	margin-left:3vw;padding-right:26px;
	background-image: url('/public/images/icons/presentationLeft.svg');
	background-size: 26px;
	background-position:left center;
}