.IntroInfo{
/*	width:var(--width);
	height:100vh;
	*/
	height:100%;
	background-color:var(--white-semi);
	text-align: center;
}



.IntroInfo .dots{width:auto;background-color:rgba(255,255,255,.9);border-radius:13px;padding:5px;bottom:20px}
.IntroInfo .title{
	background-color: var(--red);width:auto;display:inline-block;padding:7px 15px;border-radius:10px;
	position: absolute;
	bottom:60px;
	transform: translateX(-50%);
}

.IntroInfo .slides{position: absolute;top:30px;left:5px;right:5px;bottom:110px;

}

.IntroInfo .ar{position:absolute;width:40px;height:40px;background-size:contain;transform:scale(95%);top:calc(50% - 20px);cursor: pointer;;}
.IntroInfo .ar:hover{transform:scale(100%);}
.IntroInfo .ar.left {
  background-image: url("/public/images/icons/ar_left.png");
  left: 0;
}

.IntroInfo .ar.right {
  background-image: url("/public/images/icons/ar_right.png");
  right: 0;
}

.IntroInfo .slides>.content{position: absolute;top:0;left:45px;right:45px;bottom:0;
/*	background-color:rgba(255,255,255,.6);*/
	display:flex;
	align-content: center;
}

.IntroInfo .slides>.content video, .IntroInfo .slides>.content img{width:100%;height:100%;object-fit: cover;flex:0 0 none}
.IntroInfo .ctrl_btn{width:100px;height:50px;display:block;position: absolute;left:50%;top:50%;z-index:99;;}
.IntroInfo .ctrl_btn:hover{opacity: 0.9;transition: opacity 0.5s;cursor: pointer;}
.IntroInfo .ctrl_btn {
  background-image: url('/public/images/icons/video_play.png');
}

.IntroInfo .ctrl_btn.pause {
  background-image: url('/public/images/icons/video_play.png');
  display: none
}


.IntroInfo .slides>.content video{width:100%;height:auto;object-fit:contain;flex:0 0 none}