@import './fonts/fonts.css';

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
	user-select: none;
}

body {
	--red-light:#d79088;
	--red-middle:#bb2641;
	--red:#a52336;
	--red-dark:#74132a;
	
	--white: #fff;
	--white-semi: rgba(255,255,255,.2);
	--white-semi_2: rgba(255,255,255,.8);
	--light:#faf6f0;
	--silver:#ececec;
	
	--bronze-light:#f3eee0;
	--bronze-middle:#d8c59d;
	--bronze-middle-dark:#c2a381;
	--bronze-dark:#b48f65;
	--green:#31a836;

	--margin:20px;
	--header-height: 150px;
	--statusbar-height:0px; 
	--footer-height: 32px;
	--border-width: 3px;
	
	--bottom-height: 40px;
	--stage_navigation-height:71px;

	--right-width: 0.35;
	--subfolder: '/test';

	--fsize_0: calc(10px - 0.3vmin);
	--fsize_1: calc(10px - 0.1vmin);
	--fsize_2: calc(10px + 0.2vmin);
	--fsize_3: calc(10px + 0.3vmin);
	--fsize_4: calc(10px + 0.5vmin);
	--fsize_5: calc(10px + 0.7vmin);
	--fsize_6: calc(10px + 0.8vmin);
	--fsize_7: calc(10px + 1vmin);
	--fsize_8: calc(10px + 1.5vmin);
	--fsize_9: calc(10px + 2vmin);
	--fsize_15: calc(10px + 4vmin);
	
}


.App{
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
	user-select: text;
}

.App.portrait{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
	user-select: none;
}


.App.portrait{
	--right-width: 1;
	--header-height: 30vmin;
	--footer-height: 15vmin;
	--stage_navigation-height:15vmin;
	--margin:5px;
}

.ios.App{
	--statusbar-height: 0pt; 
	--header-height: calc(30vmin + var(--statusbar-height));
}


.bgimg{background-size:contain;background-position: center;background-repeat: no-repeat;}
.bgmask{mask-size:contain;mask-position: center;mask-repeat: no-repeat;}

.ia{cursor: pointer;}

.noselect{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn_highlight{position: absolute;top:0;left:0;height:100%;width: 100%;pointer-events: none;background-color:transparent}
.active .btn_highlight{	animation-name: heighlight;animation-duration: .6s;}

.qrcode{background-color: white;padding:8px;}

@keyframes heighlight {
	from { background-color: white; }
		to { background-color: transparent; }
} 

.disabled{pointer-events: none;opacity: .5;}
.hidden{display:none !important;}

input:focus{
	outline: none;
}

@-webkit-keyframes fadeIn {
	from { opacity: 0 !important; }
		to { opacity: 1 !important; }
} 
@keyframes fadeIn {
	from { opacity: 0; }
		to { opacity: 1; }
}
*:not(.exception){ animation: 0.5s ease-out 0s 1 fadeIn;}
.exception, .exception *{ animation: 0s ease-out 0s 1 fadeIn;}


/*#region scrollbar*/
.desktop *::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0);
  background-color: transparent;
}

.desktop *::-webkit-scrollbar
{

	width: 5px;
	height:5px;
	background-color: transparent;
	right:5px;
}

.desktop *::-webkit-scrollbar-thumb
{
  background-color: var(--bronze-light);
	border-radius:5px;	
}

/*#endregion scrollbar*/


html, body{width:100%;height:100%;margin:0;padding:0;overflow:hidden;}
#root{height:100%;overflow: hidden;position: fixed;}
.App{height:100%;position: fixed;}

.App{width:100%;height:calc(100% - var(--header-height) - var(--footer-height));padding-top:var(--header-height);background-color: white;}
.App>.appContent{height:100%;top:var(--header-height);overflow: hidden;position:absolute;z-index: 998;width:100%;pointer-events: none;}
.App>.appContent>div{pointer-events: all;}

.App a{text-decoration: none;color:var(--bronze-dark)}

.hr{border-bottom: 1px solid var(--bronze-dark);}


.dots{position: absolute;bottom:15px;width:auto;left:50%;transform: translateX(-50%);height:auto;z-index:101;pointer-events:none;text-align: center;background-color: var(--white-semi_2);padding:3px 8px;border-radius:10px;}
.dots>*{width:14px;height:14px;border:1px solid var(--bronze-dark);border-radius: 50%;background-color:white;display:inline-block;margin:2px 3px 0 3px;}
.dots>*>div{display:none}
.dots>*.active>div{display:block;margin:2px;background-color:var(--bronze-dark);transition: background-color 1s;width:10px;height:10px;border-radius: 50%;}

.btn{font-size:var(--fsize_5);}
.lmbrtz_btn{font-size:var(--fsize_5);border-radius: 0;border:7px solid var(--white);border-left-width: 14px;border-right-width:14px;background-color: var(--bronze-dark);cursor: pointer;padding:5px;text-align: center;}
.lmbrtz_btn.red{background-color: var(--red);}


.products{
	flex:1 1 auto;
	width:calc(100% + var(--border-width));
	overflow: auto;
	--size:170px;
	
	display: flex; 
	flex-flow: row wrap; 
	justify-content:flex-start;
	align-content:flex-start;
	/*gap: 4px;*/
	margin-left:0;
	
	
	
}

.portrait .products{
	--size:calc(100vw / 2 - var(--border-width) * 2 - 1px);	
	
}
.products>*{
	flex: 0 0 var(--size);
	box-sizing: border-box; 
	width:var(--size);
	height:var(--size);
	margin: 0 var(--border-width) var(--border-width) 0;
	display:block;	
	padding:0px;
	background-color: var(--bronze-light);
	text-decoration: none;
	color:var(--red) !important;
	text-align: left;
	overflow: hidden;
	
	word-wrap: break-word;
	-moz-hyphens: auto;
	-o-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto; 
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
  position: relative;
}
.products>a:hover{
/*	color:var(--bronze-dark) !important;*/
	background-color: var(--bronze-middle);
	transition: background-color .4s;
}
.products>a.group {
  color: var(--bronze-light) !important;
  box-sizing: border-box;
}

.products>a.group:hover {
  color: var(--white) !important;
}

.products>a>img {
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  opacity: 0;
  aspect-ratio: 1;

}

.products>a {
  background-color: var(--silver);
}

.products>a.loaded {
  background-color: var(--bronze-light);
}

.products>a:hover {
  /*	color:var(--bronze-dark) !important;*/
  background-color: var(--bronze-middle);
  transition: background-color .4s;
}

.products>a.group.loaded {
  background-color: transparent;
}

.products>a.loaded>img {
  opacity: 1;
  /*transition: opacity .5s;*/
}

.products>a.group.loaded {
  background-color: transparent;
}

.products>a.group {
  border-bottom-right-radius: 0%;
  overflow: hidden;
}

.products>a.group::after {
  position: absolute;

  background-image: url(../public/images/group_corner.png);
  background-size: cover;
  background-position: bottom right;
  width: 100%;
  height: 100%;
  bottom: 0;
  bottom: 0;
  content: '';
  pointer-events: none;

}

.products>a:hover>img {
  opacity: 0.9;
  transform: scale(1);
  transition: transform .5s, opacity .1s .5s;
  transform-origin: center;
}
.products>a.group:hover {

}
.products>a.group:hover>img {
  opacity: 0.9;
  transform: scale(1.02);
  transition: transform .5s, opacity .5s;
  transform-origin: bottom right;
}


.products>a>span {
  position: absolute;
}
.products>*>*{margin:10px;display: inline-block;}


.portrait .products>*{
	flex: 1 1 var(--size);
	width:var(--size);
	height:var(--size);	
}
.portrait .products>*:last-of-type { flex-grow: 0; }



.qrArea{--h:80px;height:var(--h);display:block;z-index: 9999;position: absolute;right:var(--margin); top:var(--margin)}
.qrArea>*{display: inline-block;}
.qrArea>img{width:auto; height:100%;margin-right:5px}
.qrcode{border:1px solid #000;height:calc(var(--h) - 18px) !important;width:calc(var(--h) - 18px) !important}

#printcontainer{display:none}

@media print {
	*{  transition: none !important }
  .App {
    visibility: hidden;
	}
	
	*:not(.exception){ animation: 0s ease-out 0s 1 fadeIn;}
	#printcontainer{display:block;position: relative;overflow: auto;background-color: #ffffff;}
	html, body{width:100%;height:auto;margin:0;padding:0;overflow:hidden;}
	#root{height:auto;overflow: auto;}

	@page {
		size: A4 landscape;
	}
}

body{position:absolute;top:0px}
.App.portrait.login{height:100vh;padding-top:0;background-color: white;overflow:hidden}

.App.portrait.login>.appContent{top:0;}

.loading{margin:var(--margin);display:flex;}


.deviceDebug{display: none;}
.__debug .deviceDebug {
  display: block;
  position: fixed;
  background-color: rgba(255, 0, 0, .2);
  padding: 10px;
  color: black;
  pointer-events: none;
  top: 40px;
  left: 0;
}