.Login
{
	margin:var(--margin);
	font-weight: lighter;
	color:var(--white);
}

.Login a{color:var(--white);}

.Login .Card-title{margin-bottom:10px;font-size:var(--fsize_15);color:var(--white-semi);font-weight: bold;}
.Login .Card-content{background-color: var(--card-bg);padding:20px;}

.Login .link{display:block;margin-top:var(--margin);}
.Login a{text-decoration: underline;}

.Login .errors{display:block; color:var(--red)}

.Login .Button{padding-right:30px;margin:30px auto 20px 100px;width:25%;display:block;}
.Login .Card-content span{color:var(--white);font-size:var(--fsize_5);line-height: 1.3;width:100%;}

.Login .text{margin-bottom:10px;display: inline-block;}

.Login span{ white-space:  pre-line;}

.Login .btns{margin-left:100px;margin-top:30px;}
.Login .btns span{margin-left:20px;display:block}

.portrait.small .Login{margin-top: -40px;}
.portrait .Login .Card-title{margin-left:20px;margin-top:20px;}
.portrait .Login .btns{margin:50px 8vmin 0 8vmin}
.portrait .Login .btns span{margin-left:0px;}

.Login .lmbrtz_btn{margin-bottom:20px;display:block;text-decoration: none;}
.Login.CardLoginSplash .btns{margin-top:70px;}