.Menu{
	--width: calc(100vw * var(--right-width) + var(--border-width));
	width:var(--width);
	bottom:0;
	border-left:var(--border-width) solid white;
	background-color: #5b0720;
	background-image: 'url(/public/images/bg.jpg)';
	background-size: cover;
	position: fixed;
	top:0;
	left:100%;
	transition: left .3s;
	z-index: 9998;
	
}
.Menu.opened{left:calc(100% - var(--width));transition: left .7s;}
.Menu>.logo {
  background-image: 'url(/public/images/lambertz-logo-transparent.png)';
  width: 100%;
  height: 220px;
  background-size: 50%;
  background-position: 40% 30%;
}
.Menu .content{position: absolute;top:var(--header-height);width:calc(100% - 10px);bottom:20px;overflow-y: auto;overflow-x: hidden;}
.Menu .content>*, .Menu .content .ia
{
	display:block;
	border-bottom: 1px solid var(--white);
	padding:6px 0px;
	text-decoration: none;
	color:var(--white);
	font-size:17.5px;
	margin: 0 32px;
}

.Menu .content .ch{margin:0;height:0;overflow:hidden;border-bottom-width: 0;padding:0}
.Menu .content .ch.opened{height:auto}
.Menu .content .prdct{background-color: rgba(0,0,0,0);}

.Menu .content>*>span, .Menu .content .ia>span{display: block;	padding:14px 25px;}

.Menu .content>*>span:hover,.Menu .content .ia>span:hover{background-color:var(--white);color:var(--red);transition: all 0.5s;}

.Menu .content .ia._1>span{}
.Menu .content .ia._1.active>span{background-color: var(--bronze-dark);color:var(--white)}

.Menu .content .ia._2>span{padding-left:calc(25px + 20px);}
.Menu .content .ia._2.active>span{background-color: var(--bronze-middle);color:var(--red)}

.Menu .content .ia._3>span{padding-left:calc(25px + 20px + 20px);}
.Menu .content .ia._3.active>span{background-color: var(--bronze-light);color:var(--red)}
.Menu .content .ia._4>span{padding-left:calc(25px + 20px + 20px + 20px );}
.Menu .content .ia._4.active>span{background-color: var(--white);color:var(--red)}


.Menu .lang{display: none !important;}
.portrait .Menu .lang{display: block !important;}