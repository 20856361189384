.SearchInput{
	position:relative;
	display:inline-block;
	--height:40px;
	height:var(--height);
	width: 305px;
	background-color: var(--white);
	border-radius:20px;
	margin:0 20px 0 60px;
}

.SearchInput input{width:calc(100% - 50px);text-indent: 15px;margin-right:50px;line-height:calc(var(--height) - 2px);color:var(--bronze-dark);border:none;background: none;}
.SearchInput .circle{position: absolute; border-radius: 50%;width:calc(var(--height) + 2px);height:calc(var(--height) + 2px);background-color: var(--bronze-dark);right:-1px;top:-1px;}
.SearchInput .ico, .SearchInput .bg{position:absolute;width:calc(100% - 4px);height:calc(100% - 4px);top:2px;left:2px;}
.SearchInput .ico {
  background-color: white;
  mask: url(/public/images/icons/search.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  cursor: pointer;
}
.SearchInput .bg{background-color:var(--white);border-radius: 50%;}

.portrait .Header .SearchInput{margin-left:4vw;}
.portrait .SearchInput{width:100%;--height:8vmin;}