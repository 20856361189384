.SearchResultsView{
	display: flex;
	flex-direction: column;
	margin:var(--margin) var(--margin) 0 var(--margin);
	height:calc(100vh - var(--header-height) - var(--footer-height) - var(--margin));
}

.SearchResultsView h1{display: block;color:var(--red);font-weight: bolder;font-size:var(--fsize_9);margin-bottom:5px;}
.SearchResultsView h2{display: block;color:var(--bronze-dark);font-weight: lighter;font-size:var(--fsize_7);}

.portrait .SearchResultsView{height:calc(100% - var(--header-height) );}