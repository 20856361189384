.MainFooter{
	width:100%;
	height:32px;
	line-height: var(	--footer-height);
	font-size:0.7em;
	font-weight: lighter;
	background-color: var(--red);
	position: absolute;
	bottom:calc(var(	--footer-height) * -1);
}

.MainFooter>*, .MainFooter>a{color:var(--white);display: inline-block;text-decoration: none;}
.MainFooter>*:hover{color:var(--bronze-light)}
.MainFooter>span:first-child{margin-left:44px;}
.MainFooter .d{padding:0 14px }

.MainFooter .version{position: absolute;left:50%;visibility: collapse;}
.MainFooter:hover .version{visibility:visible;}

.MainFooter .watermark{position: absolute;right:var(--margin);}
.MainFooter .watermark .d{display:none}
.portrait .MainFooter{display:none}