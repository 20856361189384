.TextInput{
	text-align:left;
	border-bottom:1px solid var(--white);
	display:table;
	width:100%;
	font-size: 1.3em;
}

.TextInput-content{
	color:var(--white);
	font-weight:normal;
	
	border:none;
	text-indent:.5em;	
	line-height: 2;
	pointer-events: none;
	background-color: transparent;
	font-size:80%;
	display:table-cell;
	
	border-radius: 5px;
	width:auto;
}

.TextInput .Label{display: table-cell;width:100px;font-weight: lighter;}
.TextInput .Label-content{color:var(--white)}
.TextInput-content::placeholder{font-weight:lighter;color:var(--gray-light);font-size: 80%;}
.TextInput-content:focus {background-color: var(--input_normal_bg) !important; transition: background-color .3s;}

.editable .TextInput-content{background:none; pointer-events: all;}
.error .TextInput-content{background-color:var(--input_error_bg); transition:background-color 0.3s;}
.error .TextInput-content::placeholder{}




.TextInput{display:flex;align-content: stretch;align-items: stretch;flex-direction:row;}
.TextInput .Label{flex:0 0 none;line-height: 2em;}
.TextInput .TextInput-content{flex:1 1 none;width:100%}
