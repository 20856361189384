
.ProductDetailPrintView{
	position: relative;
	width:100%;
	/*min-height:100%;*/
	width: 100vw;
/*	min-height: 100vh;	*/
	height:auto;
	min-height: 18.5cm;
	color:var(--black);
	display:block;
	align-items: stretch;
	align-content: stretch;
/*	flex-direction: column;*/
	-webkit-print-color-adjust: exact;
	page-break-after:always;
}
@media print {
	.ProductDetailPrintView{

		page-break-after:always;
		page-break-inside: avoid;
	}
}

.ProductDetailPrintView h1{color:var(--red);font-size:var(--fsize_9);font-weight:bold;}
.ProductDetailPrintView h2, .ProductDetailPrintView h3{color:var(--red);font-size:var(--fsize_7);font-weight:normal;line-height: 1.4;white-space: pre-line}
.ProductDetailPrintView h3{color:var(--bronze-dark);}

.ProductDetailPrintView .header{background-color:var(--white);border-bottom:1px solid  var(--bronze-dark);height:3cm;width:100%;flex:0 0 none;}
.ProductDetailPrintView .header>img{height:100%;}
.ProductDetailPrintView .content{flex:1 1 auto;position: relative;page-break-inside: avoid;}
.ProductDetailPrintView .content .left{position: relative;width:calc(65vw - 1.5cm);}
.ProductDetailPrintView .content .right{position: absolute;width:35vw;height:35vw;max-height:calc(100% - 0.5cm);right:0.5cm;top:0.5cm;background-size: cover;text-align:right}

.ProductDetailPrintView .content .right>img{width:calc(100% - var(--margin)); }



.ProductDetailPrintView .foot{flex:1 0 auto;}

.ProductDetailPrintView .qrArea{--h:2cm;height:var(--h); right:.5cm;top:.5cm;position: absolute;}
.ProductDetailPrintView .qrArea>*{display: inline-block;}
.ProductDetailPrintView .qrArea>img{width:auto; height:100%;margin-right:5px}
.ProductDetailPrintView .qrcode{border:1px solid #000;height:calc(var(--h) - 18px) !important;width:calc(var(--h) - 18px) !important}

.ProductDetailPrintView .content{padding:0.5cm;padding-bottom:0;background-color: white;}
.ProductDetailPrintView .content .head{position: relative;}



.ProductDetailPrintView .badges{
	margin-top:20px;
  display: flex;
  gap:var(--margin);
	margin-left: var(--margin);
}


 .ProductDetailPrintView .badges>*{height:60px;}

.ProductDetailPrintView .organic_certification{
  display: inline-block;
  font-size: 80%;
  max-width: 8ch;
  word-wrap: normal;
}


.ProductDetailPrintView .hr{margin-top:0.5cm;margin-bottom:0.5cm;}

.ProductDetailPrintView .list>div{
	background-image: url('/public/images/icons/listPoints.png');
	background-size:14px;background-position:0 5px;background-repeat: no-repeat;padding:0 0 0 25px;
	display:block;
	width: calc(100% - 30px);
	height:auto;
	line-height: 1.6;
	margin-bottom:5px;
	color:rgba(34,30,31,1);
	font-weight: lighter;
	font-size: calc(15px * 1);
}

.portrait .ProductDetailPrintView .split{margin:0.5cm}
.ProductDetailPrintView .split{height:4cm;display:flex;}
.ProductDetailPrintView .split>*{flex:1 1 auto;overflow:hidden}
.ProductDetailPrintView .split>img{flex:none;height:100%;}
.ProductDetailPrintView .split .ingredients{width:unset;padding-left:0.1cm;--scale:1}


.ProductDetailPrintView table{display: table;page-break-after:always;page-break-inside:avoid !important; overflow: visible !important;}
.ProductDetailPrintView table tbody{display:  table-row-group;}
.ProductDetailPrintView table thead{display:  table-header-group;}
.ProductDetailPrintView table thead tr{height:2cm}
.ProductDetailPrintView table tr{display: table-row;  }
.ProductDetailPrintView table td, .ProductDetailPrintView table th{display:  table-cell;  }


.ProductDetailPrintView table.packages{margin:0.5cm;color:rgba(34,30,31,1);font-weight: lighter;text-align: left;font-size: 80%;width:calc(100% - 1cm);}
.ProductDetailPrintView table.packages a{color:var(--red);text-decoration: none;font-weight: normal;}
.ProductDetailPrintView table.packages td, .ProductDetailPrintView table.packages th{
	padding:10px;white-space: pre-line;vertical-align: middle;border:1px solid var(--bronze-light);font-size:100%;
}
.ProductDetailPrintView table.packages th{}
.ProductDetailPrintView table.packages thead{font-weight: normal;color:var(--white);background-color: var(--bronze-middle);}
.ProductDetailPrintView table.packages tbody td{white-space: pre-line;vertical-align: middle;border:1px solid var(--bronze-light)}

.ProductDetailPrintView table.packages tbody tr{background-color: var(--bronze-light);transition: background-color .2s;}
.ProductDetailPrintView table.packages tbody tr:nth-child(odd){background-color:transparent}

