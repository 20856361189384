.Header{
	--height:calc(var(--header-height) - var(--border-width));
	width:100%;
	
	height:var(--height);
	border-bottom:var(--border-width) solid white;
	background-color: var(--bronze-dark);
	position: absolute;
	top:0;
}
.Header .c{margin-top:var(--statusbar-height);width:100%;position:top;height:calc(100% - var(--statusbar-height))}

.Header .logo {
  position: absolute;
  left: 0px;
  width: 250px;
  height: 100%;
  background-image: url('/public/images/lambertz-logo.png');
  background-position: left center;
}


.menu_btn{
	display:inline-block;
	width:66px;
	height:66px;
	position: absolute;
	right:10px;
	bottom:calc(var(--height) / 2 - var(--statusbar-height));
	transform: translateY(50%);
	background-image: url('/public/images/icons/menu.png');
	background-size: 46px;
	z-index: 9999;
}


.menu_btn.opened{
	background-image: url('/public/images/icons/menuX.svg');
}

.Header .right{position: absolute;right:90px;text-align: right;bottom:50%;display:flex; align-items: center;transform: translateY(50%);width:auto}
.portrait .Header .right{width:calc(100% - 60px)}

.user_btn{
	display:flex;
	flex-direction: row;
	
	align-items: center;
	height:66px;
	padding-right:66px;
	right:10px;
	background-image: url('/public/images/icons/person.png');
	background-size: 46px;
	background-position:right center;
	color:var(--white);
}
.user_btn>div>span{display:block;font-weight: lighter;}
.user_btn>div>span.company{font-weight: normal;}


.portrait .Header .langSelect{display:none}
.Header .langSelect{
  color:var(--bronze-dark);
  background-color:var(--bronze-light);
  width:50px;
  height:50px;
  border-radius: 50%;
  line-height: 50px;text-align: center;
  margin-right: 10px;
  
}


.Header .mobilefooter{position:fixed;bottom:0;height:var(--footer-height);background-color:var(--red);width:100%;text-align: center;z-index: 9996;}
.Header .mobilefooter>*{
	display:inline-block;
	width:var(--footer-height);
	height:100%;
	background-color: var(--red);
	margin:0 5px;
}
.Header .mobilefooter>*.active{	background: linear-gradient(0deg, var(--red) 0%, rgba(255,255,255,.5) 100%);}
.Header .mobilefooter>*>div{background-color: var(--white);width:100%;height:100%;mask-size: 50%;}
.Header .mobilefooter .f_user>div {
  mask-image: url(/public/images/icons/mobile/profilFreeIcon.svg);
}

.Header .mobilefooter .f_products>div {
  mask-image: url(/public/images/icons/mobile/productFreeIconGreat.svg);
}

.Header .mobilefooter .f_presentation>div {
  mask-image: url(/public/images/icons/mobile/presentationWhiteFreeIcon.svg);
}


.portrait .Header .title{position: absolute;bottom:1vmin;color:var(--white);text-align: center;width:100%;line-height: 1.2}
.portrait .Header .title h1{font-size: var(--fsize_8);text-transform: uppercase;}
.portrait .Header .title h2{font-size: var(--fsize_5);;}

.portrait .Header .right{right:60px;bottom:calc(50% - var(--statusbar-height));}

.portrait.login .Header{background:none;border-bottom-width: 0;z-index: 9999;}
.portrait.login .Header .right .BackButton{display: none;}
.portrait.login .Header .logo{left:0px;top:10vmin;width:100%;height:15vh;background-position:center;}
.portrait.login .Header .SearchInput{display:none}
.portrait.login .mobilefooter{display:none}

.portrait .Header .menu_btn{background-size:30px;}

.splash.portrait .Header .menu_btn{display:none}
.splash.portrait .Header .menu_btn{display:none}
.splash.portrait .Header .logo{pointer-events: none;}

